import './App.css';
import { useEffect, useState } from 'react';
import Options from './Components/Options';
import Output from './Components/Output';
import quijote from './data/quijote'
import dictionary from './data/dictionary'

function App() {

  const [paragraph, setParagraph] = useState([])
  const [initParagraph, setInitParagraph] = useState('random')
  const [tag, setTag] = useState('p')
  const [inputValue, setInputValue] = useState(1)
  const [includeHtml, setIncludeHtml] = useState("No")
  const [reGenerate, setReGenerate] = useState(true)
  
  const [language, setLanguage] = useState('ES')
  const [interfaceData, setInterfaceData] = useState('')
 

  useEffect( () => {
    if(language === 'ES'){
      setInterfaceData(dictionary.es)
    } else if(language === 'FR') {
      setInterfaceData(dictionary.fr)
    }
  },[language])

  useEffect( () => {
    let initial
    if(initParagraph === "random") {
      initial = Math.floor(Math.random() * 250)
    } else {
      initial = 0
    }
    setParagraph(quijote[initial])
    getData(quijote, initial)
  }, [inputValue, initParagraph, reGenerate])

  const getData = (array, initialValue) => {
    setParagraph([])
    for(let i = 0; i < inputValue; i++){
      setParagraph( prev => [...prev, array[initialValue+i]])
    }
  }

  const handleCopyToClipboard = () => {
    let outputText = ''
    if(includeHtml === "Yes"){
      let mappedOutputText = paragraph.map( sentence => `<${tag}>${sentence}<${tag}>`)
      outputText = mappedOutputText.join('')
      console.log(String(outputText))
    } else {
      outputText = paragraph.join('')
    }
    navigator.clipboard.writeText(outputText)
  }

  const handleLanguageSelection = (e) => {
    setLanguage(e.target.innerText)
  }

  return (
    <div className="App">
      <div className='container'>
        <div className='title'>
          <div className='header'>
            <div className='languages'>
              <p className='lang-es' 
                id='lang-es'
                onClick={handleLanguageSelection}
                >ES</p>
              <p className='lang-fr' 
                id='lang-fr'
                onClick={handleLanguageSelection}
              >FR</p>
            </div>
            <h1>Generador de Lorem Ipsum Quijotesco</h1>
          </div>
          <p>{interfaceData.subTitle}</p>
          <p className='book-name'>"{interfaceData.bookName}"</p>
        </div>
        <Options 
          paragraph={paragraph}
          includeHtml={includeHtml}
          setIncludeHtml={setIncludeHtml}
          inputValue={inputValue}
          setInputValue={setInputValue}
          tag={tag}
          setTag={setTag}
          initParagraph={initParagraph}
          setInitParagraph={setInitParagraph}
          setParagraph={setParagraph}
          reGenerate={reGenerate}
          setReGenerate={setReGenerate}
          interfaceData={interfaceData}
        />
        <div className='btn-copy-section'>
          <button className='btn-copy' onClick={handleCopyToClipboard} >
            <span>{interfaceData.copyBtn}</span>
          </button>
        </div>
        <Output 
          paragraph={paragraph}
          includeHtml={includeHtml}
          tag={tag}

        />
        <footer>by <a href='https://jjmdev.xyz' target="_blank">jjmdev.xyz</a></footer>
      </div>
    </div>
  );
}

export default App;
