import React from 'react'

const Output = (props) => {
  const { paragraph, includeHtml, tag } = props
  return (
    <div className='output'>
      {
        includeHtml === "Yes" ?
          (<p>{ paragraph.map(sentence => `<${tag}>${sentence}<${tag}>`)}</p>)
          :
          (<p>{ paragraph.map(sentence => sentence) }</p>)
      }
    </div>
  )
}

export default Output